import React, { useEffect, useRef, useState } from 'react';
import { T } from "../../utils/i18n-config"
import Summernote from '../utils/Summernote';


interface ConditionalParagraphWithSelectorOptionProps {
  key: string;
  index: number;
  value: string;
  message: string;
  isEditable: boolean;
  showModal: boolean;
  handleEditValue: (index: number, value: string) => void;
  handleEditMessage: (index: number, message: string) => void;
  handleSetIsEditable: (index: number, isEditable: boolean) => void;
}


const ConditionalParagraphWithSelectorOption = ({index, value, message, isEditable, showModal, handleEditValue, handleEditMessage, handleSetIsEditable }: ConditionalParagraphWithSelectorOptionProps) => {
  
  const handleEditorChange = (content: string) => {
    handleEditMessage(index, content);
  };

  const handleSetLocalIsEditable = () => {
    handleSetIsEditable(index, !isEditable);
  }
  
  return (
    <div>
      <div className="form-group">
        <h6 className="mb-0">{T("Option")}</h6>
        <input type="text" className="form-control" placeholder={T('Enter option')} value={value} onChange={(e) => handleEditValue(index, e.target.value)} />
      </div>
      <div className="form-group">
        <label>{T("Message")}</label>
        <Summernote value={message} onChange={handleEditorChange} showModal={showModal} />
      </div>
      <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={isEditable} onChange={() => handleSetLocalIsEditable()} />
          <label className="custom-control-label">{T("Is Editable")}</label>
      </div>
      <hr className="horizontal dark my-3"></hr>
    </div>
  );
};

export default ConditionalParagraphWithSelectorOption
