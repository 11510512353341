import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ProbabilityAnalysisElement } from '../../models/special-document/ProbabilityAnalysisElement';
import ViewModeBase from "./ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';
import ViewModeProps from './ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import { Constants } from '../../utils/Constants';
import DOMPurify from 'isomorphic-dompurify';
import { ElementArgs } from '@models/special-document/ElementArgs';


const ProbabilityAnalysisViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ProbabilityAnalysisElement>(iElement as ProbabilityAnalysisElement).current
  const [content, setContent] = useState<string>("")
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [probabilityTemp, setProbabilityTemp] = useState<string>(element.optionValueSelected)
  const [probabilitySelected, setProbabilitySelected] = useState<string>(element.optionValueSelected)

  useEffect(() => {
    const modifiedText = element.text_default.replace(/{{probability}}/g, T(probabilitySelected))
    setContent(modifiedText)
  },[element, probabilitySelected]);

  const handleEdit = () => {
    setShowConfigurationsModal(true);
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    element.optionValueSelected = probabilityTemp
    const elementWithoutTextDefault = {...element.args}
    delete elementWithoutTextDefault.text_default
    let success = await  elementRepository.saveElement("view", elementWithoutTextDefault)
    if (success) {
      setProbabilitySelected(probabilityTemp)
    } else {
      setProbabilityTemp(probabilitySelected)
      window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
    setProbabilityTemp(probabilitySelected)
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProbabilityTemp(event.target.value)
  }


  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
        isAlertWarning={element.is_alert_warning}>
        <div className={`${Constants.CONTENT_CLASS}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Select the probability analysis")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="form-group">
          <label htmlFor="probabilitySelect">{T("Probability")}</label>
          <select className="form-control" id="probabilitySelect" value={probabilityTemp} onChange={handleSelectChange}>
            <option value="Low">{T("Low")}</option>
            <option value="Medium">{T("Medium")}</option>
            <option value="High">{T("High")}</option>
          </select>
        </div>
        
      </ModalWithButtons>
    </div>
  );
};

export default ProbabilityAnalysisViewMode;
