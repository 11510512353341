import { ElementType } from "../../models/special-document/ElementType";
import { ElementTypeRepository } from "../../repository/special-document/ElementTypeRepository";
import { Constants } from "../../utils/Constants";


export class ElementTypeFactory{
  private  _elementTypeRepository: ElementTypeRepository

  constructor(elementTypeRepository: ElementTypeRepository = new ElementTypeRepository()){
    this._elementTypeRepository = elementTypeRepository
  }
  
  
  getElementType(type: number): ElementType{
    return this._elementTypeRepository.getElementByType(type)
  }

  getElementTypes(reference:string): ElementType[]{
    if(reference.includes(Constants.FINDING_REFERENCE)){
      return this._elementTypeRepository.getAllElements().filter(element => 
        element.type === Constants.PARAGRAPH_ELEMENT_TYPE || 
        element.type === Constants.FINDING_ELEMENT_TYPE || 
        element.type === Constants.FINDING_EVALUATION_ELEMENT_TYPE
      )
    }
    else if(reference.includes(Constants.RISK_REFERENCE)){
      return this._elementTypeRepository.getAllElements().filter(element =>
        element.type !== Constants.FINDING_ELEMENT_TYPE && 
        element.type !== Constants.FINDING_EVALUATION_ELEMENT_TYPE
      )
    }
    return this._elementTypeRepository.getAllElements().filter(element => 
      element.type !== Constants.FINDING_ELEMENT_TYPE && 
      element.type !== Constants.FINDING_EVALUATION_ELEMENT_TYPE &&
      element.type !== Constants.VARIANCE_ANALYSIS_NOTES_TYPE
    )
  }
  
}