import React, { useEffect, useRef, useState } from 'react';
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphElementWithSelector } from "../../models/special-document/ConditionalParagraphElementWithSelector"
import EditModeBase from "./EditModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import ConditionalParagraphWithSelectorOption from './ConditionalParagraphWithSelectorOption'
import { ConditionalParagraphOption } from "../../models/special-document/ElementArgs"
import { createId } from "../../utils/utilities";
import { Permissions } from '../../models/special-document/ElementBase';
import DOMPurify from 'isomorphic-dompurify';


const ConditionalParagraphWithSelectorEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ConditionalParagraphElementWithSelector>(iElement as ConditionalParagraphElementWithSelector).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [question, setQuestion] = useState('')
  const [options, setOptions] = useState<ConditionalParagraphOption[]>([])
  const [isEditable , setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    setQuestion(element.question)
    setOptions(JSON.parse(JSON.stringify(element.options)))

    if(element.options.length > 0){
      const currentOptions = element.options;
      if(currentOptions.some(option => option.editable)){
        setIsEditable(true);
      }
    }
    else{
      setIsEditable(false);
    }
  }, []); 

  const handleConfiguration = () => {
    setQuestion(element.question)
    setOptions(JSON.parse(JSON.stringify(element.options)));
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const elementRepository = new ElementRepository()
    const lastQuestion = element.question
    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.question = question
    element.options = JSON.parse(JSON.stringify(options))

    let success = await elementRepository.saveElement("edition", element.args)
    //let success = false
    if (!success) {
      element.question = lastQuestion
      element.options = lastOptions
      setQuestion(lastQuestion)
      setOptions(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setOptions([])
    setQuestion('')
    setIsEditable(element.options.some(option => option.editable));
  };

  const handleAddValueMessage = () => {
    const conditionalParagraphOption: ConditionalParagraphOption = {
      id: createId(),
      value: "",
      message: "",
      editable: false
    }
    const newOptions = [...options, conditionalParagraphOption]
    setOptions(newOptions)
  }

  const handleRemoveLastValueMessage = () => {
    const newOptions = [...options]
    newOptions.pop()
    setOptions(newOptions)
  }

  const handleEditValue = (index: number, value: string) => {
    const newOptions = [...options]
    newOptions[index].value = value
    setOptions(newOptions)
  }

  const handleEditMessage = (index: number, message: string) => {
    const newOptions = [...options]
    newOptions[index].message = message
    setOptions(newOptions)
  }

  const handleSetIsEditable = (index: number, editable: boolean) => {
    const newOptions = [...options];
    newOptions[index].editable = editable;
    setOptions(newOptions);
    if(editable){
      setIsEditable(true);
    }
    else if(!newOptions.some(option => option.editable)){
        setIsEditable(false);
    }
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div>
          {element.options.length === 0 && 
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Add question")}</h6>
              <div className="chart pt-3">
                <p>{T("Add Option/Message")}</p>
              </div>
            </div>
          </div>
          }
          {element.options.length > 0 && 
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{element.question}</h6>
              <div className="chart pt-3">
                <ul>
                  {element.options.map((option) => {
                    return (
                      <li key={option.id}>
                        <div className="row">
                          <div className="col-2">
                            <span className="text-sm font-weight-normal">{option.value}</span>
                          </div>
                          <div className="col-10 d-flex">
                            <div style={{minWidth: "20px"}}>
                              {option.editable && element.permissions?.update && <i className="fas fa-pencil-alt text-primary"></i>}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.message) }} />
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          }
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <button 
            className="btn btn-primary btn-sm me-2" 
            type="button" 
            style={{padding: "8px 8px", fontSize: "12px"}}
            onClick={handleAddValueMessage}>
            {T("Add Option/Message")}
          </button>
          <button 
            className="btn btn-outline-danger" 
            type="button" 
            style={{padding: "8px 8px", fontSize: "12px"}}
            onClick={handleRemoveLastValueMessage}>
              {T("Remove last Option/Message")}
          </button>
        </div>
        <div className="form-group">
          <label>{T("Question")}</label>
          <input type="text" className="form-control"placeholder={T('Enter question')} value={question} onChange={(e) => setQuestion(e.target.value)} />
        </div>
        <hr className="horizontal dark my-3"></hr>
        <div>
          {options.map((option, index) => {
            return (
            <ConditionalParagraphWithSelectorOption
              key={`${option.id}-${options.length}`}
              index={index}
              value={option.value}
              message={option.message}
              isEditable={option.editable}
              showModal={showConfigurationsModal}
              handleEditValue={handleEditValue}
              handleEditMessage={handleEditMessage}
              handleSetIsEditable={handleSetIsEditable}/>
            )
          })}
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default ConditionalParagraphWithSelectorEditMode;
