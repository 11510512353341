import React, { useEffect, useState } from 'react'
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphElementWithSelector } from "../../models/special-document/ConditionalParagraphElementWithSelector"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { ConditionalParagraphOption } from "../../models/special-document/ElementArgs"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import Summernote from '../utils/Summernote';
import { Permissions } from '../../models/special-document/ElementBase'
import DOMPurify from 'isomorphic-dompurify';
import { Constants } from '../../utils/Constants';

const ConditionalParagraphWithSelectorViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<ConditionalParagraphElementWithSelector>(iElement as ConditionalParagraphElementWithSelector)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [optionIdSelected, setOptionIdSelected] = useState(element.optionIdSelected)
  const [optionSelected, setOptionSelected] = useState<ConditionalParagraphOption | undefined>(undefined)

  useEffect(() => {
    setOptionIdSelected(element.optionIdSelected)
    if(element.optionIdSelected != null)
      setOptionSelected(JSON.parse(JSON.stringify(element.getOptionSelected())));
  },[]);

  const handleEdit = () => {
    setOptionIdSelected(element.optionIdSelected)
    if(element.optionIdSelected != null)
      setOptionSelected(JSON.parse(JSON.stringify(element.getOptionSelected())));
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const elementRepository = new ElementRepository()
    const lastOptionIdSelected = element.optionIdSelected
    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.optionIdSelected = optionIdSelected
    element.options.forEach((option) => {
      if(option.id === optionIdSelected) {
        option.message = optionSelected?.message ?? ""
        option.value = optionSelected?.value ?? ""
        option.editable = optionSelected?.editable ?? false
      }
    })

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.optionIdSelected = lastOptionIdSelected
      element.options = lastOptions
      window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
    setOptionSelected(undefined)
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      setOptionIdSelected(null);
      setOptionSelected(undefined);
      return;
    }
    setOptionIdSelected(parseInt(event.target.value, 10));
    setOptionSelected(JSON.parse(JSON.stringify(element.getOptionById(parseInt(event.target.value, 10)))));
  };

  const handleEditorChange = (content: string) => {
    if (optionSelected != undefined){
      optionSelected.message = content;
    }
    setOptionSelected(JSON.parse(JSON.stringify(optionSelected)));
  };

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}>
        {element.options.length === 0 && 
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Without configuration")}</h6>
              <div className="chart pt-3">
                <p>{T("Configure this element from the template.")}</p>
              </div>
            </div>
          </div>
        }
        {element.options.length > 0 && element.optionIdSelected === null &&
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{element.question}</h6>
              <div className="chart pt-3">
                <p>{T("Select an answer.")}</p>
              </div>
            </div>
          </div>
        }
        {element.options.length > 0 && element.optionIdSelected != null &&
          <div className={`${Constants.CONTENT_CLASS}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.getMessage())}} />
        }
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={element.question}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <select value={optionIdSelected ?? ""} onChange={handleSelectChange} className="form-select">
        <option value="">{T("Select an answer.")}</option>
          {element.options.map((option) => (
            <option key={option.id} value={option.id} selected={option.id === element.optionIdSelected}>
              {T(option.value)}
            </option>
          ))}
        </select>
        {element.options.length > 0 && optionIdSelected != null && optionSelected != undefined && optionSelected.editable === false &&
          <div className='mt-4' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(optionSelected.message)}} />
        }
        {element.options.length > 0 && optionIdSelected != null && optionSelected != undefined && optionSelected.editable === true &&
          <Summernote value={optionSelected.message} onChange={handleEditorChange} showModal={showConfigurationsModal} />
        }
      </ModalWithButtons>
    </div>
  );
};

export default ConditionalParagraphWithSelectorViewMode;
