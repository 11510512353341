/**
 * Creates a unique identifier based on the current date.
 * @returns {number} - A unique identifier.
 */

export function createId(): number {
  const ahora = new Date();
  return ahora.getTime();
}


/**
  * Returns the current date in the format 'YYYY-MM-DD'.
  * @param {string} text - The text to be copied to the clipboard.
  * @returns {string} - The current date in the format 'YYYY-MM-DD'.
 */

export async function copyTextToClipboard(text: string): Promise<void> {
  if (navigator.clipboard && window.isSecureContext) {
    // if clipboard API is available and the code is running in a secure context
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  } else {
    // if clipboard API is not available or the code is not running in a secure context
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  }
}


/**
 * Modifies the table name by capitalizing each word and removing numbers if the name includes 'independence_format_table'.
 * @param {string} name - The original table name.
 * @returns {string} - The modified table name.
 */

export function modifyThreeStatesTableName(name: string): string {
  const words = name.replace(/_/g, " ").split(" ");
  let formattedTitle = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  if (name.includes("independence_format_table")) {
    formattedTitle = formattedTitle.replace(/[0-9]/g, "");
  }
  return formattedTitle.replace(/\s+/g, " ").trim();
}
