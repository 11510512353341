import { ElementArgs } from './ElementArgs'
import { ElementBase, IElementBase} from './ElementBase'

export class AttachDocumentElement extends ElementBase implements IElementBase{
  private _extensions: string[]
  private _fileCount: number
  private _buttonDescription: string = "Attach Document"
  constructor(args:ElementArgs){
    super(args)
    this._extensions = args.extensions? args.extensions : []
    this._fileCount = args.fileCount? args.fileCount : 0
    this._buttonDescription = args.buttonDescription? args.buttonDescription : "Attach Document"
  }

  get extensions(): string[] {
    return this._extensions
  }
  get fileCount(): number {
    return this._fileCount
  }
  get buttonDescription(): string {
    return this._buttonDescription
  }
  set extensions(value: string[]) {
    this._extensions = value
  }
  set fileCount(value: number) {
    this._fileCount = value
  }
  set buttonDescription(value: string) {
    this._buttonDescription = value
  }


 

  render(): void {
    console.log("AttachDocumentElement render") 
  }
}