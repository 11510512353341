
export const Constants = {

  // Other constants
  CTR_POP_UP: 'ctr-pop-up',
  NOTIFICATION_FETCH_INTERVAL: 1000 * 60,
  CONTENT_CLASS: 'document-content',
  //--------------------------------------------

  // Paragraph
  PARAGRAPH_ELEMENT_TYPE : 1,
  //--------------------------------------------

  // Findings
  FINDING_ELEMENT_TYPE : 15,
  FINDING_EVALUATION_ELEMENT_TYPE : 16,
  FINDING_REFERENCE: 'finding_template',
  //--------------------------------------------

  // Risks and Variance Analysis
  VARIANCE_ANALYSIS_NOTES_TYPE: 12,
  RISK_REFERENCE: 'risk',
  //--------------------------------------------

  // Probability Analysis
  PROBABILITY_ANALYSIS_TYPE: 18,
  //--------------------------------------------
}
